/* src/footer.css */
.footer {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1rem 3rem;
    background-color: #F6F6F6;
    color: #fff;
    box-shadow: 0px -2px 10px #dddddd;
}

.footerDiv {
    display: flex;
    flex-direction: column;
    gap: 50px;
    width: 100%;
}

.upperDiv {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;
}

.lowerDiv {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.menuHeading {
    color: black;
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 0;
}

.menuText {
    color: black;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 2px;
    cursor: pointer;
}

.menu {
    display: flex;
    flex-direction: column;
    gap: 10px;
}

.header-logo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-logo h1 {
    margin: 0;
    font-size: 1.5rem;
}

.headerHeading {
    color: #000;
}

.headerHeading1 {
    color: #5B7EF0;
}

.termsText {
    color: #000;
}

.socialDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}