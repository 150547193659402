.heading {
    font-size: 28px;
    font-weight: 700;
    margin: 0;
}

.mainDiv {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.releaseText {
    font-size: 220px;
    color: #DADADA;
    font-weight: 700;
}

.image {
    width: 400px;
    height: 400px;
    object-fit: cover;
    border-radius: 5px;
    box-shadow: rgba(50, 50, 93, 0.25) 0px 6px 12px -2px, rgba(0, 0, 0, 0.3) 0px 3px 7px -3px;
}

.imageDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    transform: translate(0px, -110px);
    gap: 45px;
}

.searchDiv {
    padding: 7px 20px;
    background-color: rgb(236, 236, 236);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
}

.searchInput {
    outline: none;
    border: 0;
    width: 550px;
    padding: 5px 10px;
    font-size: 18px;
    /* text-align: center; */
    background-color: rgb(236, 236, 236);
    border-left: 1px solid #c1c1c1;
}

.searchInput:focus {
    outline: none;
    border: 0;
    border-left: 1px solid #c1c1c1;
}

.searchSelect {
    outline: none;
    border: 0;
    padding: 5px;
    font-size: 18px;
    background-color: rgb(236, 236, 236);
    text-align: left;
    width: auto;
}

.searchSelect:focus {
    outline: none;
    border: 0;
}