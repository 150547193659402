/* src/Header.css */
.header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.8rem 2rem;
    background-color: #F6F6F6;
    color: #fff;
    box-shadow: 0px 2px 10px #dddddd;
    position: sticky;
    top: 0;
    z-index: 999;
    background-color: rgba(255, 255, 255, 0.466);
    /* Slightly transparent */
    backdrop-filter: blur(10px);
    /* Apply blur effect */
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    /* Optional shadow for depth */
}

.header-logo {
    display: flex;
    align-items: center;
    gap: 5px;
}

.header-logo h1 {
    margin: 0;
    font-size: 1.5rem;
}

.header-nav {
    display: flex;
    gap: 1rem;
}

.header-link {
    color: #fff;
    text-decoration: none;
}

.header-link:hover {
    text-decoration: underline;
}

.headerHeading {
    color: #000;
    margin: 0;
    font-weight: 700;
}

.headerHeading1 {
    color: #5B7EF0;
    cursor: pointer;
}

.loginText {
    color: #000;
    margin: 0;
}

.loginIconDiv {
    border-radius: 500px;
    background-color: rgb(236, 236, 236);
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.loginDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}