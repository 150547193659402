.infoHeading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 7px;
    color: #5B7EF0;
}

.infoText {
    margin-bottom: 2px;
    font-weight: 400 !important;
}

.leftMainDiv {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.react-tabs__tab {
    padding: 10px 20px;
    cursor: pointer;
    color: #333;
    border-bottom: 2px solid transparent;
}

.react-tabs__tab--selected {
    background-color: #007bff !important;
    border-bottom: 2px solid #007bff !important;
    font-weight: bold !important;
    color: white !important;
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover {
    color: #555;
}

.nameDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.artistName {
    font-size: 22px;
}

.linkHover:hover {
    text-decoration: underline;
    color: #007bff;
}