.infoHeading {
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 7px;
    color: #5B7EF0;
}

.infoText {
    margin-bottom: 2px;
    font-weight: 700;
}

.leftMainDiv {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.react-tabs__tab {
    padding: 10px 20px;
    cursor: pointer;
    color: #333;
    border-bottom: 2px solid transparent;
}

.react-tabs__tab--selected {
    background-color: #007bff !important;
    border-bottom: 2px solid #007bff !important;
    font-weight: bold !important;
    color: white !important;
}

.react-tabs__tab:not(.react-tabs__tab--selected):hover {
    color: #555;
}

.nameDiv {
    display: flex;
    align-items: center;
    gap: 10px;
}

.artistName {
    font-size: 24px;
}

.coverImage {
    width: 125px;
    height: 125px;
}

.coverimageDiv {
    /* flex: 1; */
    width: 100%;
    display: flex;
    justify-content: center;
}

.tableHeading {
    font-weight: 700;
    margin: 0;
}

.infoDivs {
    display: flex;
    align-items: flex-start;
    gap: 30px;
}

.releaseDiv {
    max-height: 500px;
    overflow-y: auto;
}