/* .App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
} */

/* src/App.css */
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html,
body,
#root {
  height: 100%;
}

.app-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.content {
  flex: 1;
  padding: 2rem;
  /* Adjust this padding as necessary for your layout */
}

/* Scrollbar track */
::-webkit-scrollbar {
  width: 12px;
  /* Set width for vertical scrollbar */
  height: 12px;
  /* Set height for horizontal scrollbar */
}

/* Scrollbar track */
::-webkit-scrollbar-track {
  background-color: #f0f0f0;
  /* Background of the track */
  border-radius: 10px;
}

/* Scrollbar handle */
::-webkit-scrollbar-thumb {
  background-color: #c3c3c3;
  /* Color of the scroll thumb */
  border-radius: 10px;
  border: 2px solid #f0f0f0;
  /* Add a border to match track color */
}

/* Scrollbar handle on hover */
::-webkit-scrollbar-thumb:hover {
  background-color: #555;
  /* Darken on hover */
}